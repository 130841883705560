import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Form, CheckBoxWithLabel, Input } from '../components/forms/form_for';
import Layout from './join_bundle_layout';
import { ConditionalComponent } from '../components/common';
import withJoin from '../components/HOC/withJoin';
import { StudentTracker } from '../services/StudentTracker';

function CourseInstructionsInSchool({i18n, schoolName }) {
  const instructionsParagraph = i18n.schoolInstructions['registrationParagraph'];
  const title = i18n.schoolInstructions['title'];

  return (
    <div>
      <h3
        className="!directional lg:text-[1.25rem] text-[28px] text-[#333] font-bold !leading-normal rtl:lg:text-right ltr:lg:text-start text-center"
        dangerouslySetInnerHTML={{
          __html: title.replace('%{school_name}', schoolName),
        }}
      />
      <div
        className="!directional !mt-0 lg:text-base text-lg text-[#333] font-normal tracking-[0.245px] lg:text-right text-center"
        dangerouslySetInnerHTML={{ __html: instructionsParagraph }}
      />
    </div>
  );
}

function StandaloneCourseInstructions({ i18n }) {
  return (
    <h3
      className="!directional lg:text-[1.25rem] text-[28px] text-[#333] font-bold !leading-normal rtl:lg:text-right ltr:lg:text-start text-center"
      dangerouslySetInnerHTML={{
        __html: i18n.courseInstructions.title,
      }}
    />
  );
}

const PaidCoursePaymentInfo = connect(mapStateToProps)(({ finalPrice, i18n, currencyType, discount, price }) => (
  <div>
    <span className="text-[#333] text-[1.25rem]">{i18n.coursePrice} <br /></span>
    <div className="flex items-baseline text-[#333]">
      <span>
        <span className="font-normal text-3xl">{currencyType}</span>
        <span className="font-bold text-4xl">{finalPrice}</span>
      </span>
      {!!discount && (
        <del className="text-rose-600 font-normal flex items-baseline text-2xl rtl:flex-row-reverse ltr:flex-row ltr:ml-1 rtl:mr-1">
          <span>{currencyType}</span>
          <span>{price}</span>
        </del>
      )}
    </div>
    <span className="text-[#333] text-base font-normal tracking-[0.245px]">{i18n.startPaidCourse}</span>
  </div>
));

const PaidCoursePaymentInfoMobile = connect(mapStateToProps)(({ finalPrice, i18n, currencyType, discount, price }) => (
  <>
    <div className="mb-6">
      <div className="flex items-baseline justify-center">
        <span className="rtl:ml-2 ltr:mr-2 text-2xl text-[#333]">{i18n.coursePrice}:</span>
        <span className="font-bold bg-brand-200 px-2 text-2xl text-[23px] text-[#333] rounded">
          <span className="rtl:ml-1 ltr:mr-1">{currencyType}</span>
          <span>{finalPrice}</span>
        </span>
        {!!discount && (
          <del className="text-rose-600 font-normal flex items-baseline text-2xl rtl:mr-1 ltr:ml-1">
            <span>{currencyType}</span>
            <span>{price}</span>
          </del>
        )}
      </div>
    </div>
    <span className="flex justify-center text-[#333] text-lg font-normal">{i18n.startPaidCourse}</span>
  </>
));

const JoinCourseHeader = connect(mapStateToProps)(({ finalPrice, i18n, price }) => {
  const joinCourseParagraph = i18n.registrationParagraph;

  return (
    <div style={{ marginTop: 5 }} className="mb-6">
      {price > 0 ? (
        <div className="lg:block hidden"><PaidCoursePaymentInfo /></div>
      ) : (
        <p
          className="!directional text-[#333] lg:text-base text-lg font-normal tracking-[0.245px] lg:text-start text-center"
          dangerouslySetInnerHTML={{ __html: joinCourseParagraph }}
        />
      )}
    </div>
  );
});

const JoinCourseMainContent = connect(mapStateToProps)(
  withJoin(
    class extends React.Component {
      componentDidMount() {
        const { eventProps, ga4DisabledEventsCodes } = this.props;
        StudentTracker.pageView(eventProps, ga4DisabledEventsCodes);
      }

      render() {
        const { i18n, schoolName, finalPrice, price, discount, showNameField, showPhoneField, phoneRequired, nameRequired, email, phone, name, validateBeforeSubmit, loginPath, i18nForm, bundleId, couponId, terms, getFlushContainerErrors, isTermsNotApproved, termLinkClick, bundle } = this.props;
        const isPaymentRequired = price > 0;
        const submitButtonText = i18n.form[isPaymentRequired ? 'payButton' : 'registerButton'];
        const submitButtonAriaLabel = i18n.form[isPaymentRequired ? 'payButtonAriaLabel' : 'registerButtonAriaLabel'];

        return (
          <div className="col course-reg">
            {finalPrice <= 0 && (
              schoolName.length ? (
                <CourseInstructionsInSchool {...this.props} />
              ) : (
                <StandaloneCourseInstructions i18n={i18n} />
              )
            )}

            <JoinCourseHeader />

            <Form for="user" action="new_school_user_registration" method="POST" className="form-simple form-fields">
              <Input type="hidden" name="bundleid" value={bundleId} />
              {showNameField && (
                <Input
                  className="input-field text-base font-normal tracking-[0.245px] !rounded-sm !h-[52px] !w-full !mt-3 !px-5 lg:!mt-0"
                  name="name"
                  type="text"
                  aria-label={i18n.form.fullNameAriaLabel}
                  placeholder={i18n.form.fullName}
                  defaultValue={name}
                  required={nameRequired}
                  customValidity={i18n.form.fullNameError}
                />
              )}
              <Input
                className="input-field text-base font-normal tracking-[0.245px] !rounded-sm !h-[52px] !w-full !mt-3 !px-5"
                name="email"
                required
                type="email"
                aria-label={i18n.form.emailAriaLabel}
                placeholder={i18n.form.email}
                defaultValue={email}
                customValidity={i18n.form.emailError}
              />
              {showPhoneField && (
                <Input
                  className="input-field text-base font-normal tracking-[0.245px] !rounded-sm !h-[52px] !w-full !mt-3 !px-5"
                  name="phone"
                  type="tel"
                  aria-label={i18n.form.phoneAriaLabel}
                  placeholder={i18n.form.phone}
                  defaultValue={phone}
                  pattern={i18n.form.phonePattern}
                  required={phoneRequired}
                  customValidity={i18n.form.phoneError}
                />
              )}

              {bundle && <input type='hidden' name='initial_bundle_id' value={bundle} />}
              {couponId && <Input type="hidden" name="coupon_id" value={couponId} />}

              <ConditionalComponent isRender={!isEmpty(terms)}>
                <CheckBoxWithLabel
                  className="directional mt-3 tracking-[0.245px] text-[#333]"
                  name="is_terms_approved"
                  label={terms}
                  onClick={termLinkClick}
                  testName="terms"
                  ariaLabel={i18n.termsAndConditionAriaLabel}
                />
              </ConditionalComponent>
              <ConditionalComponent isRender={isTermsNotApproved}>
                <div className="feedback-invalid" dangerouslySetInnerHTML={{ __html: i18n.form.termsError }} />
              </ConditionalComponent>
              <ConditionalComponent isRender={getFlushContainerErrors() !== ''}>
                <div className="feedback-invalid">
                  {getFlushContainerErrors()
                    .split(',')
                    .map((error, index) => (
                      <div key={index}>{error}</div>
                    ))}
                </div>
              </ConditionalComponent>
              <div className="mt-[10px] flex flex-col-reverse">
                <div className="pt-[10px] text-center lg:ltr:text-start lg:text-right text-[#333]" dangerouslySetInnerHTML={{ __html: i18n.form.loginLink.replace('%{target_link}', loginPath) }} />
                <button
                  className="cta-btn remove-uppercase !w-full !font-semibold !h-auto py-4 mt-3 rtl"
                  onClick={validateBeforeSubmit}
                  aria-label={submitButtonAriaLabel}
                  dangerouslySetInnerHTML={{ __html: submitButtonText }}
                />
              </div>
            </Form>
          </div>
        );
      }
    }
  )
);

function JoinCourse() {
  return (
    <Layout
      Content={JoinCourseMainContent}
      MobileContent={PaidCoursePaymentInfoMobile}
    />
  );
}

function mapStateToProps(state) {
  const price = Number(state.$$layout.getIn(['default_bundle_props', 'price']) || 0);
  const discount = Number(state.$$layout.getIn(['default_bundle_props', 'discount']) || 0);
  let prefillData = {};
  try {
    prefillData = JSON.parse(state.$$account.get('prefill_data'));
  } catch (err) {
    // pass
  }

  return {
    newUserButtonIcon: state.$$layout.getIn(['default_bundle_props', 'paid_for']) ? 'cart' : 'registration',
    loginPath: state.$$layout.getIn(['urls', 'login']),
    bundleId: state.$$layout.getIn(['default_bundle_props', 'id']),
    bundleName: state.$$layout.getIn(['default_bundle_props', 'name']),
    email: prefillData.email ? decodeURIComponent(prefillData.email) : '',
    phone: prefillData.phone ? decodeURIComponent(prefillData.phone) : '',
    name: prefillData.name ? decodeURIComponent(prefillData.name) : '',
    instructor: state.$$layout.getIn(['default_bundle_props', 'instructor']).replace(/\n/g, '<br />'),
    audience: state.$$layout.getIn(['default_bundle_props', 'audience']).replace(/\n/g, '<br />'),
    description: state.$$layout.getIn(['default_bundle_props', 'description']).replace(/\n/g, '<br />'),
    currencyType: state.$$layout.getIn(['default_bundle_props', 'currency_type'])
      ? state.$$layout.getIn(['default_bundle_props', 'currency_type']).match(/[^\s()]+/)[0]
      : '₪',
    price: state.$$layout.getIn(['default_bundle_props', 'price']),
    discount: state.$$layout.getIn(['default_bundle_props', 'discount']),
    finalPrice: price - discount,
    phoneRequired: state.$$layout.getIn(['fields', 'phone']) === 'required',
    nameRequired: state.$$layout.getIn(['fields', 'name']) === 'required',
    showPhoneField: state.$$layout.getIn(['fields', 'phone']) !== 'hidden',
    showNameField: state.$$layout.getIn(['fields', 'name']) !== 'hidden',
    schoolName: state.$$school.get('name'),
    termsAttachmentUrl: state.$$layout.getIn(['urls', 'terms_attachments']),
    terms: state.$$layout.get('terms'),
    couponId: state.$$layout.get('coupon_id'),
    customDomain: state.$$layout.get('customDomain'),
    ga4DisabledEventsCodes: state.$$layout.get('ga4_disabled_events_codes'),
    eventProps: state.$$layout.get('event_props').toJS(),
    i18n: {
      ...state.$$i18n.getIn(['students', 'joinCourse']).toJS(),
      form: state.$$i18n.getIn(['students', 'form']).toJS()
    }
  };
}

export default connect(mapStateToProps)(JoinCourse);
