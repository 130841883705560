
import React from 'react';
import { connect } from 'react-redux';

import Container from './container';
import cartActions from '../actions/cart';
import Layout from './join_bundle_layout';
import { StudentTracker } from '../services/StudentTracker';
import LocalStorageManager from '../../../lib/localStorageManager';
import { CoursePaymentLogo } from '../components/svg/join_bundle';

function BundleShoppingCartHeader(props) {
  const { bundleName, i18n } = props;

  return (
    <div className="text-center lg:mb-4">
      <div className='mb-4 lg:mb-6'><CoursePaymentLogo /></div>
      <h2 className="!mt-0 font-bold lg:text-[20px] text-[28px] text-[#333] leading-normal">{i18n.courseTitle}</h2>
      <p className='lg:!mt-0 mt-[32px] font-bold lg:font-semibold !text-[#333] text-[22px] lg:text-lg pb-2 lg:pb-0'>{bundleName}</p>
    </div>
  );
}

function SchoolShoppingCartHeader(props) {
  return (
    <div className="text-center lg:mb-4">
      <div className='mb-4 lg:mb-6'><CoursePaymentLogo /></div>
      <h3 className="!mt-0 font-bold lg:text-[20px] text-[28px] text-[#333] leading-normal" dangerouslySetInnerHTML={{__html: props.i18n.schoolTitle.replace('%{course_name}', props.bundleName)}}></h3>
      <p className='lg:!mt-0 mt-[32px] font-bold lg:font-semibold !text-[#333] text-[22px] lg:text-lg pb-2 lg:pb-0'>{props.bundleName}</p>
    </div>
  );
}

function ternary(key, ComponentYes, ComponentNo) {
  return function (props) {
    if (props[key]) {
      return <ComponentYes {...props} />;
    } else {
      return <ComponentNo {...props} />;
    }
  };
}

const ShoppingCartHeader = ternary('isExplicitSchool', SchoolShoppingCartHeader, BundleShoppingCartHeader);


const ShoppingCartPage = connect(mapStateToProps)(class ShoppingCartPage extends Container {
  checker = null;

  static subheader = 'simple';

  static propTypes = {
    dispatch: React.PropTypes.func,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (!this.props.error){
      if ((this.props.bundleAdded === true || this.props.isExplicitSchool) && this.props.finalPrice > 0){
        this.trackAddedToCart(this.props);
        LocalStorageManager.save('couponName', this.props.couponName);
        LocalStorageManager.save('couponDiscount', this.props.couponDiscount);
        LocalStorageManager.save('finalPrice', this.props.finalPrice);
      }
    }
  }

  triggerRemove(id) {
    this.props.dispatch(cartActions.removeFromCart(id));
  }

  renderPayNow() {
    const { form, finalPrice } = this.props;

    if (finalPrice === 0) {
      return this.renderRedeemCouponButton();
    } else if (form) {
      return this.renderPayNowForm();
    } else {
      return this.renderPayNowButton();
    }
  }

  payWithCoupon(e) {
    e.stopPropagation();
    this.props.dispatch(cartActions.updateCouponOrder());
  }

  renderRedeemCouponButton() {
    const { i18n } = this.props;

    return (
      <button
        className="cta-btn remove-uppercase !w-full !h-auto !font-semibold py-4 mt-3 rtl"
        data-testname="join-with-coupon"
        onClick={(e) => this.payWithCoupon(e)}
        aria-label={ i18n.couponButtonAriaLabel }
        dangerouslySetInnerHTML={{ __html: i18n.couponButton }}
      />
    );
  }

  renderPayNowButton() {
    const { payNowUrl, i18n } = this.props;

    return (
      <button
        className="cta-btn remove-uppercase !w-full !h-auto !font-semibold py-4 mt-3 rtl"
        onClick={(e) => this.validatePayNowButton(e)}
        aria-label={i18n.payButtonAriaLabel}
        dangerouslySetInnerHTML={{ __html: i18n.payButton }}
      />
    );
  }

  renderPayNowForm() {
    const { form, i18n } = this.props;

    return (
      <div>
        <form id='paymentForm'></form>
        <button
          className="cta-btn remove-uppercase !w-full !h-auto !font-semibold py-4 mt-3 rtl"
          onClick={(e) => this.processOrder(e)}
          aria-label={i18n.payButtonAriaLabel}
          dangerouslySetInnerHTML={{ __html: i18n.payButton }}
        />
      </div>
    );
  }

  validatePayNowButton(e) {
    e.preventDefault();
    e.target.classList.add('nm-btn', 'disabled');
    e.target.style.pointerEvents = 'none';

    this.trackViewCart(this.props);

    const { orderId, finalPrice, payNowUrl } = this.props;
    this.props.dispatch(cartActions.checkOrderValid(orderId, finalPrice))
        .then((value) => {
          if(value.order.sum == finalPrice){
            this.props.dispatch(cartActions.processOrder(orderId));
            location.href = payNowUrl;
          }else{
            location.href = location.href.split('?')[0] + `?from=${finalPrice}&to=${value.order.sum}`;
          }
        });
  }

  processOrder(e) {
    e.target.classList.add('nm-btn', 'disabled');
    e.target.style.pointerEvents = 'none';

    const { orderId, finalPrice } = this.props;
    this.props.dispatch(cartActions.checkOrderValid(orderId, finalPrice))
        .then(function (value) {
           if(value.order.sum == finalPrice){
             this.creatAndSubmitDynamicForm();
           }else{
            location.href = location.href.split('?')[0] + `?from=${finalPrice}&to=${value.order.sum}`;
           }
        }.bind(this))
  }

  creatAndSubmitDynamicForm() {
    this.trackViewCart(this.props);

    const { form, orderId } = this.props;
    var dynamicForm = document.getElementById("paymentForm");
    dynamicForm.setAttribute("method", "post");
    dynamicForm.setAttribute("action", form.get('url'));

    var fields = form.get('fields')
    fields.map((v, k) => {
      var field = document.createElement("input")
      field.setAttribute("type", "text")
      field.setAttribute("name", k)
      field.setAttribute("value", v)
      field.setAttribute("type", "hidden")
      dynamicForm.appendChild(field)
    })
    this.props.dispatch(cartActions.processOrder(orderId));
    dynamicForm.submit();
  }

  trackAddedToCart = (props) => {
    StudentTracker.track('add_to_cart', {
      'value': props.finalPrice,
      'currency': 'ILS',
      'items': this.itemProps(props)
    });
  }

  itemProps = (props) => {
    let items = [
      {
        'item_id': `${props.bundleId}`,
        'item_name': props.bundleName,
        'item_brand': props.teacherName,
        'item_brand_id': props.teacherId,
        'item_category': props.schoolName,
        'item_category2': props.bundlePolicy,
        'coupon': props.couponName,
        'currency': "ILS",
        'discount': props.discount,
        'price': props.finalPrice,
        'quantity': 1
      }
    ];
    return items;
  }

  trackViewCart = (props) => {
    StudentTracker.track('view_cart', {
      'value': props.finalPrice,
      'currency': 'ILS',
      'items': this.itemProps(props)
    });
  }

  render() {
    const { finalPrice, discount, currencyType, priceBeforeDiscount, isExplicitSchool, i18n } = this.props;

    return (
      <div className='lg:mb-0 mb-7'>
        <div>
          <div>
            <ShoppingCartHeader {...this.props} />
            <div className='pb-5 lg:border-t lg:border-[#E6E6E6] lg:border-solid lg:pt-5 lg:pb-0'>
              <div className='flex items-baseline justify-center'>
                <span className="text-[#333] text-[24px] lg:text-[1.25rem] mx-1">{i18n.coursePrice}:</span>
                <div className="flex items-baseline text-[#333]">
                  <span className="text-[#333] px-1.5 bg-brand-200 rounded lg:px-0 lg:bg-transparent lg:rounded-none">
                    <span className="font-bold lg:font-normal text-2xl">{currencyType}</span>
                    <span className="font-bold text-3xl">{finalPrice}</span>
                  </span>
                  {!!discount && (
                    <del className="text-rose-600 font-normal flex items-baseline text-lg rtl:mr-1 ltr:ml-1">
                      <span>{currencyType}</span>
                      <span>{priceBeforeDiscount}</span>
                    </del>
                  )}
                </div>
              </div>
            </div>
            {this.renderPayNow()}
          </div>
        </div>
      </div>
    );
  }
});

export default class Page extends React.Component {
  render() {
    return (
      <Layout
        Content={ShoppingCartPage}
        MobileContent={() => <div />}
        isShoppingCart={true}
      />
    );
  }
}

function mapStateToProps(state) {
  const discount = state.$$cart.get('discount');

  return {
    bundleName: state.$$cart.get('bundle_name'),
    bundleId: state.$$cart.get('bundle_id'),
    bundlePolicy: state.$$cart.get('bundle_policy'),
    discount,
    finalPrice: state.$$cart.get('price_after_discount'),
    currencyType: state.$$cart.get('currency_type') ? state.$$cart.get('currency_type').match(/[^\s()]+/)[0] : '₪',
    form: state.$$cart.get('form'),
    i18n: {
      ...state.$$i18n.getIn(['students', 'cart']).toJS(),
      ...state.$$i18n.getIn(['students', 'joinCourse']).toJS(),
    },
    isExplicitSchool: state.$$school.get('explicit'),
    orderId: state.$$cart.get('order_id'),
    payNowUrl: state.$$layout.getIn(['urls', 'paynow']),
    priceBeforeDiscount: state.$$cart.get('price_before_discount'),
    schoolId: state.$$cart.get('school_id'),
    schoolName: state.$$school.get('name'),
    signedIn: state.$$account.get('signedIn'),
    teacherId: state.$$cart.get('teacher_id'),
    teacherName: state.$$cart.get('teacher_name'),
    couponName: state.$$cart.get('coupon_name'),
    couponDiscount: state.$$cart.get('coupon_discount'),
    bundleAdded: state.$$layout.get('bundle_added'),
    discountedPrice: state.$$cart.get('discounted_price'),
  };
}
